<template>
	<div>
		<v-container fluid style="padding:0px; margin-bottom: -128px;" :class="displaySizeClass">
			<div style="width:100%; height:128px; margin-top:-128px; background-color:gray;" v-if="isSmallScreen!='Mobile'"></div>
			<v-card 
				class="mx-auto"
				flat
			>
				<v-card-title style="justify-content:center;">
					<h1 style="word-break: break-word;">{{resetPasswordLabel[languageId]}}</h1>
				</v-card-title>
				<v-card-text style="max-width:500px; min-height:200px;" class="mx-auto" >
					<v-row no-gutters >
						<v-col cols="12" style="padding:20px;">
							<v-form ref="resetForm">
								<v-text-field type="text" :label="emailTokenLabel[languageId]" v-model="form.token" :rules="emailTokenRules[languageId]"></v-text-field>
								<v-text-field type="text" :label="emailLabel[languageId]" v-model="form.email" :rules="emailRules[languageId]"></v-text-field>
								<v-text-field :label="passwordLabel[languageId]" v-model="form.password" :append-icon="typePassword2?'mdi-eye':'mdi-eye-off'" @click:append="typePassword2=!typePassword2" :type="typePassword2?'password':'text'" :rules="passwordRules[languageId]"></v-text-field>
							</v-form>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions style="max-width:500px; " class="mx-auto">
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="resetPassword()" >
						{{resetPasswordLabel[languageId]}}
					</v-btn>
				</v-card-actions>
			</v-card>
			
			<div  style="position:absolute; bottom:0; width:100%">
				<footerComponent :backgroundImage="footerImage"></footerComponent>
			</div>
		</v-container>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import footerComponent from './components/Footer'
const baseURL = process.env.VUE_APP_BASE_URL_API;
const captchaKey = process.env.VUE_APP_CAPTCHA_KEY;
	export default {
		name: 'ResetPassword',
		components: { footerComponent },
		data: () => ({
			pageTitle:['Recuperar contraseña Karlus Morales','Reset Password Karlus Morales'],
			pageMetaTitle:['Recuperar contraseña Karlus Morales','Reset Password Karlus Morales'],
			pageDescription:['Página para recuperar contraseña de la cuenta en Karlus Morales','Page to reset account password of Karlus Morales'],
			pageKeywords:['Recuperar contraseña','Reset Password'],
			metaImage:'',
            resetPasswordLabel:["Actualizar contraseña","Reset password"],
			emailTokenLabel:["Token","Token"],
			passwordResetSuccessLabel:["Exito al cambiar contraseña", "Password reset successful"],
			
            emailTokenRules: [
				[
					v => !!v || 'El token es necesario.',
					// v => /^[a-zA-Z0-9]+$/.test(v) || 'El nombre solo debe contener letras.',
				],
				[
					v => !!v || 'Token is required.',
					// v => /^[a-zA-Z0-9]+$/.test(v) || 'Name requires only letters.',
				]
            ],
            emailRules: [
				[
					v => !!v || 'El email es necesario.',
					// v => /.+@.+\..+/.test(v) || 'El email debe tener un formato válido.',
					v => /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'El email debe tener un formato válido.',
				],
				[
					v => !!v || 'Email is required.',
					// v => /.+@.+\..+/.test(v) || 'Email format not valid.',
					v => /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'Email format not valid.',
				]
            ],
            passwordRules: [
                [
                    v => !!v || 'La contraseña es necesaria.',
                    v => v.length >= 6 || 'Se requieren por lo menos 6 caracteres.',
                    v => /\d/.test(v) || 'Se requiere un número.',
                    v => /[a-z]/.test(v) || 'Se requiere una minúscula.',
                    v => /[A-Z]/.test(v) || 'Se requiere una mayúscula.',
                    v => /[!@#$%^&*)(+=._-]/.test(v) || 'Se requiere un caracter especial.',
                ],
                [
                    v => !!v || 'Password is required.',
                    v => v.length >= 6 || 'At least 6 characters required.',
                    v => /\d/.test(v) || 'A number is required.',
                    v => /[a-z]/.test(v) || 'A lowercase is required.',
                    v => /[A-Z]/.test(v) || 'An uppercase is required.',
                    v => /[!@#$%^&*)(+=._-]/.test(v) || 'A special character is required.',
                ]
            ],
			footerImage:require('../assets/About/About_Desktop_06.jpg'),
            typePassword2:true,
            form:{
                token:'',
                captcha:'',
                email:'',
                password:''
            },
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),

        }),
        computed: {
            ...mapState("shoppingCart", 
                [
                    "languageId", "emailLabel", "passwordLabel",
                ]),
			isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
        },
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'noindex, nofollow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
		async mounted () {
            await this.loadRecaptcha(document, "script", "google-recaptcha");
			
		},
		beforeDestroy(){
			document.getElementsByClassName("grecaptcha-badge")[0].style.display="none";
		},
		methods: {
            async loadRecaptcha(d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
					d.getElementsByClassName("grecaptcha-badge")[0].style.display="block";
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://www.google.com/recaptcha/api.js?render="+captchaKey;
                fjs.parentNode.insertBefore(js, fjs);
            },
			resetPassword(){
				if(this.$refs.resetForm.validate()){
					window.grecaptcha.ready(() => {
						window.grecaptcha.execute(captchaKey, {action: 'submit'})
							.then((token) => {
								this.resetPasswordRequest(token);
							});
					})
				}
			},
			resetPasswordRequest(token){
				this.form.captcha = token;
				axios.post(`${baseURL}Account/reset-password`,this.form)
					.then((res) => {
						if(res.status == 200){
							alert(this.passwordResetSuccessLabel[this.languageId])
						}
					})
					.catch(error => { 
						console.log(error);
					});
			},
		},
	}
</script>
